@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Oswald:wght@400;600&display=block&family=Playfair+Display:wght@400&family=Roboto:wght@400&family=DM+Serif+Display:wght@400&family=Quicksand:wght@500;700&family=DM+Serif+Display&family=Roboto+Condensed:wght@400;700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Rubik:ital,wght@0,400;0,700;1,400;1,700&family=EB+Garamond:ital,wght@0,400;0,600;1,400;1,600&family=Poppins&family=Prata&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Tenor+Sans&display=swap");

$image-max-width: 465px;

@font-face {
    font-family: 'Cooper Hewitt Bold';
    src: url('fonts/cooperhewitt-bold-webfont.woff2') format('woff2'),
        url('fonts/cooperhewitt-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url('fonts/cooperhewitt-book-webfont.woff2') format('woff2'),
        url('fonts/cooperhewitt-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Breathing';
    src: url('fonts/breathing.woff2') format('woff2'),
        url('fonts/breathing.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@mixin half-column() {
	@include make-col-ready();
	@include make-col(6);

	@include mobile-version() {
		@include make-col(12);
	}

	margin-top: auto;
	margin-bottom: auto;
}

// Standardizing on two targeted sizes:
@mixin mobile-version() {
	@include media-breakpoint-down(sm) {
		@content;
	}
}
@mixin desktop-version() {
	@include media-breakpoint-up(sm) {
		@content;
	}
}
// And one special case middle ground size
@mixin small-desktop-version() {
	@include media-breakpoint-between(sm, lg) {
		@content;	
	}
}

#root {
	font-size: calc(14px + 1.2vw); // Start from 14px and scale up a bit with the browser width
	
	&.is-editing {
		padding-bottom: calc(0.6em + 0.75em + 0.5rem); // Account for the margins, padding, and text height of the toolbar
	}
}

body.is-editing .rs-modal-wrapper {
	top: 58px; // Account for the top bar	
}

.bio {
	@include make-container();
	a.cta-button {
		align-items: center;
		padding: 0.2em 0.8em;
		text-decoration: none;
	}
	
	.video-display-popup, .image-display-popup {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(128, 128, 128, 0.8);
		text-align: center;
		display: flex;
		z-index: 20000;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		
		.video-row {
			position: relative;
			margin-top: 0.4em;
			flex: 9;
			width: 100%;
			video {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
		
		img {
			object-fit: contain;
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
		}
		
		.cta-button-row {
			margin-top: 0.4em;
			margin-bottom: 0.4em;	
		}
		
		.video-display-close-button, .image-display-close-button {
			position: absolute;
			right: 7px;	
			top: 7px;
			color: white;
			filter: drop-shadow(1px 1px 2px #000);
		}
	}
}

.intro-row {
	@include make-row();
	@include desktop-version() {
		align-items: center;
		padding-top: 0.5em;
		padding-right: 3em;
		padding-bottom: 0.5em;
		padding-left: 3em;
	}
	justify-content: center;
	
	.intro-photo-column {
		@include desktop-version() {
			@include make-col(4);
		}

		.intro-photo {
			.edit-button-for-adding {
				position: absolute;	
			}
			.edit-button {
				z-index: 1;	
			}
			padding: 1em;
			margin: 1em;
			position: relative;
			@include desktop-version() {
				padding: 0.4em;
				max-width: $image-max-width;
				margin-left: auto;
			}
			@include mobile-version() {
				margin: 1em;
				padding: 0.6em;
			}

			img.intro-photo-image {
				width: 100%;
				height: auto;
			}
			.video-play-button {
				.edit-button {
					position: absolute;
					width: max-content;
					left: 50%;
					transform: translateX(-50%) translateY(-100%);
				}
				position: absolute;
				left: 50%;
				width: 4em;
				height: 4em;
				transform: translateX(-50%) translateY(-50%);
				.video-play-button-image {
					position: absolute;
					width: 100%;
					height: 100%;
					&.unopened-prompter {
						animation: glow 0.7s infinite;
					
						@keyframes glow {
							0%, 100%  { filter: drop-shadow(0 0 0.15rem rgb(255, 255, 255)) }
							50% { filter: drop-shadow(0 0 0.15rem rgba(0, 0, 0, 0)) }
						}
					}
				}
			}
		}
	}
	.intro-text-column {
		@include desktop-version() {
			@include make-col(8);
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.intro-text {
			text-align: center;
			padding: 2em 1em 2em 1em;
			margin: 1em;
			position: relative;
			font-size: 100%;
			@include desktop-version() {
				font-size: 70%;
			}
			@include mobile-version() {
				padding: 1em;	
				margin-top: 0;
				margin-bottom: 0;
			}

			h1 {
				margin-bottom: 0.7em;
				font-size: 160%;
			}
			
			.intro-text-body {
				@include desktop-version {
					font-size: 100%;
				}
				font-weight: 500;
				p {
					margin-bottom: 1em;
				}
			}
			a.cta-button {
				text-decoration: none;
				display: inline-flex;
				margin: auto;
				margin-top: 1em;
				.edit-button {
					position: static;
				}
			}
		}
	}
}

.links-row {
	@include make-row();
	margin-top: 0;
	padding-top: 1em;
	padding-bottom: 1em;
	
	.links-column {
		@include make-col-ready();
		@include make-col(12);
		
		.links-link-list-row {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			width: max-content;
			max-width: 100%;
			margin: auto;
			@include desktop-version() {
				min-width: 15em;
			}
			@include mobile-version() {
				min-width: 90%;	
			}
			
					
			a.link {
				.edit-button {
					top: 0;
					display: inline-block;
					position: static;
				}
				@include mobile-version() {
					font-size: 100%;
				}
				@include desktop-version() {
					font-size: 70%;
				}
				text-decoration: none;
				padding-left: 0.2em;
				padding-right: 0.2em;
				width: 100%;
				max-width: 600px;
				margin-bottom: 1em;
				display: flex;
				justify-content: space-between;
			}
			.add-link-button {
				
			}
		}		
	}
}
.carousel-row {
	@include make-row();
	padding-top: 1em;
	padding-bottom: 1em;
	.carousel-column {
		@include make-col-ready();
		@include make-col(12);
		
		.swiper-pagination {
			line-height: 0;	
		}
		.swiper-pagination-bullet-active {
			background-color: #626262;
		}
		.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
			bottom: 3px;	
		}
		
		.carousel-cards {
			.carousel-previous-button, .carousel-next-button {
				display: flex;
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 1;
				align-items: center;
				img {
					width: 40px;
					height: auto;
				}
			}
			.carousel-previous-button {
				left: 8px;
			}
			.carousel-next-button {
				right: 8px;
			}
			.swiper-button-disabled {
				display: none;
			}
			.swiper-slide {
				padding-left: 0.5em;
				padding-right: 0.5em;
				.carousel-card {
					.card-photo-column {
						padding: 0 2em;
						text-align: center;
						img {
							width: 100%;
							height: auto;
							box-sizing: border-box;
							@include mobile-version() {
								max-width: 400px;
								border: 0.6em solid white
							}
							@include desktop-version() {
								max-width: $image-max-width;
								border: 0.4em solid white
							}
						}
					}
					.card-contents-column {
						@include desktop-version() {
							font-size: 70%;	
						}
						@include mobile-version() {
							padding-bottom: 21px;							
						}
						text-align: center;
						h2.card-heading {
							font-weight: 500;
							font-size: 1.3em;
							text-align: center;
						}
						div.card-body {
							text-align: center;
							white-space: pre-wrap; // Allow for newlines
							font-weight: 500;
							font-size: 92%;
						}
						a.card-button {
							display: inline-flex;
							text-align: center;					
							text-decoration: none;
							margin: auto;
							font-weight: 500;
						}
						.edit-button {
							position: static;
						}
					}		
				}
			}
		}
	}
}
.testimonial-row {
	@include make-row();
	@include desktop-version() {
		justify-content: center;
		padding: 1em 3em;
	}
	@include mobile-version() {
		padding-top: 1em;
		padding-bottom: 1em;	
	}
	.testimonial-photo-column {
		@include half-column();
		padding: 0 1em;
		
		.testimonial-photo-wrapper {
			@include desktop-version() {
				padding-left: 6em;
				padding-right: 2em;	
				display: flex;
			}
			@include small-desktop-version() {
				padding-left: 2em;	
			}
			@include mobile-version() {
				padding-left: 1em;			
				padding-right: 1em;			
			}
			img.testimonial-photo {
				@include desktop-version() {
					max-width: $image-max-width;
					border: 0.4em solid white;
				}
				@include mobile-version() {
					border: 0.5em solid white;
				}
				box-sizing: border-box;
				width: 100%;
				height: auto;
				align-self: flex-start; // Works around a problem with images being too tall in Safari
			}
		}
	}
	.testimonial-contents-column {
		@include half-column();
		@include desktop-version() {
			font-size: 70%;
			padding-left: 1em;
			padding-bottom: 0;
		}
		h2.testimonial-heading {
			@include desktop-version() {
				margin-top: 0;	
			}
			@include mobile-version() {
				margin-top: 0.6em;	
			}
			
			font-size: 1.3em;
			font-weight: 500;
			text-align: center;
		}
		.testimonial-body {
			text-align: center;
			white-space: pre-wrap; // Allow for newlines
			margin-top: 1em;
			font-weight: 500;
		}
		.testimonial-attribution {
			margin-top: 1em;
			font-weight: 500;
			font-size: 0.8em;
			text-align: center;
		}
		.edit-button {
			position: static;
		}		
	}
}

.contact-row {
	@include make-row();
	text-align: center;
	justify-content: center;
	@include mobile-version() {
		flex-direction: column-reverse;
		align-items: center;
		padding-top: 1em;
		padding-bottom: 1em;
	}
	@include desktop-version() {
		font-size: 70%;
		padding: 1em 4.3em;
		align-items: center;
	}

	.contact-column {
		@include make-col-ready();
		@include desktop-version() {
			@include make-col(6);				
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		@include mobile-version() {
			@include make-col(12);
		}
		.edit-button-for-adding {
			margin-bottom: 0.6em;	
		}
		h3.contact-name {
			font-weight: 500;
			font-size: 1.3em;
			margin-bottom: 0.4em;
			.edit-button {
				position: static;
				display: inline;
				margin-left: 0.3em;
			}
		}
		ul.contact-list {
			@include mobile-version() {
				margin-top: 0.9em;					
			}
			@include desktop-version() {
				margin-top: 0.4em;					
			}
			list-style-type: none;
			padding-left: 0;
			li {
				line-height: 110%;
				margin-bottom: 0.5em;
				@include desktop-version() {
					line-height: 150%;
				}
				a {
					font-weight: 500;
					font-size: 100%;
					text-decoration: none;
					color: black;
				}
			}
		}
		ul.contact-click-to-contact {
			list-style-type: none;
			padding-left: 0;
			display: flex;
			justify-content: center;
			li {
				display: inline;
				padding-left: 0.8em;
				padding-right: 0.8em;
				a {
					font-size: 150%;
					text-decoration: none;
				}
			}
		}
		div.contact-vcard {
			button.contact-vcard-open-button {
				display: inline-block;
				padding: 0.2em 0.4em;
				margin-bottom: 0.5em;
				border-radius: 0.5em;
				text-decoration: none;
				font-size: smaller;
				background-color: black;
				color: white;
			}
		}
		ul.contact-social-media-list {
			margin-bottom: 0;
			li {
				a {
					i {
						font-size: 0.9em;
					}	
				} 
			}
		}
	}
	.contact-accent-column {
		@include make-col-ready();
		@include desktop-version() {
			@include make-col(6);
		}
		@include mobile-version() {
			@include make-col(12);
			padding: 1em 2em;
			padding-top: 0;
			max-width: 20em;
		}
		.contact-accent-photo-container {
			@include desktop-version() {
				padding-left: 3em;
				padding-right: 9em;	
			}
			@include small-desktop-version() {
				padding-right: 3em;	
			}
			img.contact-accent-photo {
				width: 100%;
				@include desktop-version() {
					border: 0.65em solid white;	
					max-width: $image-max-width;
				}
				@include mobile-version() {
					border: 0.6em solid white;
				}
			}
		}
	}
	// Social media list will appear in one of the above two columns depending on the theme settings.
	ul.contact-social-media-list {
		list-style-type: none;
		padding-left: 0;
		li {
			display: inline;
			margin-left: 0.3em;
			margin-right: 0.3em;
			a {
				i {
					font-size: 1.2em;
				}
			}
			.edit-button {
				display: inline;
				position: static;
				font-size: 0.85em;
			}
		}
	}
}

footer {
	@include make-row();
	justify-content: center;
	background-color: #efefef;
	padding: 0.6em;
	a {
		display: flex;
		justify-content: center;
		img {
			width: 90%;
			max-width: 300px;
		}
	}
}

.edit-panel {
	width: 98%;
	max-width: 800px;
	.edit-panel-body {
		margin-top: 0;
		padding-bottom: 0;
		h1 {
			color: white;
			text-align: center;
		}
		.edit-panel-help {
			color: white;
			text-align: center;
			
			.tips-button {
				display: block;
				width: fit-content;
				margin: auto;
				
				button {
					background-color: #3F8390;
					display: inline-block;
					margin: 0.3em;
					font-family: 'Montserrat';
					font-weight: 600;
					color: white;
					border: none;
					padding: 0.375rem 0.75rem;
					border-radius: 0.25rem;
				}
			}
		}
		.edit-panel-group-label {
			color: white;
			font-weight: 600;
			margin-top: 2em;
			margin-bottom: 0.5em;	
		}
		.edit-panel-field {
			margin-bottom: 0.6em;
			label {
				@extend .form-label;
				font-size: 100%;
				margin-bottom: 0.2em;
				color: white;
			}
			label.treat-phone-number-as-sms-prompt {
				margin-top: 0.5em;
				select {
					background-color: #484F5B;
					border: 1px solid #888;
					margin-left: 0.3em;
					margin-right: 0.3em;
				}
			}
			input, textarea {
				@extend .form-control;
			}
			.input-with-side-button {
				display: flex;
				flex-wrap: wrap;
				input {
					flex: 20;
				}
				.help-button {
					background: none;
					color: #f52ad4;
					flex: 0 1;
					padding-left: 1em;
				}
				.help-button-content {
					margin-top: 0.5em;
					margin-bottom: 0.5em;
					color: white;
					width: 100%;
					a {
						color: white;	
					}
				}
				.delete-button {
					margin-right: 0.5em;
					background: #3F8390;
					color: white;
					border-radius: 0.25rem;	
					margin-left: 0.5em;
					padding-left: 0.5em;
					padding-right: 0.5em;
					margin-right: 0;
				}
			}
			.move-link-buttons {
				display: flex;
				button {
					margin-right: 0.5em;
					background: #3F8390;
					color: white;	
					border-radius: 0.25rem;
				}
			}
			.add-answer-button {
				margin-right: 0.5em;
				background: #3F8390;
				color: white;
				border-radius: 0.25rem;	
			}
			.photo-crop-zoom {
				margin-top: 0.5em;	
				margin-bottom: 0.5em;
			}
			.filename {
				color: white;	
			}
		}
		.edit-panel-file-delete-field {
			button {
				color: red;	
				padding: 0;
				background: none;
			}
		}
		.photo-edit-cropper-container {
			position: relative;
			height: 12em;
			.photo-edit-cropper {
				
			}
		}
		.sun-editor-editable p {
			color: black;	
		}
	}
	.edit-panel-meta-controls {
		.edit-panel-field {
			margin: 0.3em;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			label {
				display: flex;
				align-items: center;
				text-align: center;
				color: white;	
				margin-right: 0.5em;
			}
			select {
				color: black;	
			}
		}
	}
	.pro-tip {
		background-color: #90AAB0;
		display: inline-flex;
		align-items: center;
		padding: 0.3em 0.5em;
		margin-top: 0.5em;
		i {
			color: white;
			font-size: 1.2em;
			margin-right: 0.3em;
		}
		figcaption {
			color: #063745;
			text-align: left;
			span.pro-tip-label {
				font-weight: 600;
				text-decoration: underline;
			}
		}
	}
}

.edit-button {
	i {
		display: inline-block;
		margin-left: 0.05em;
		margin-right: 0.05em;
	}
	span {
		margin-left: 0.05em;
		margin-right: 0.05em;
	}
	display: block;
	font-size: calc(12px + 1.2vw); // Start from 12px and scale up a bit with the browser width
	position: absolute;
	right: 0.2em;
	top: 0.2em;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	color: white;
	background-color: #F52AD4;
	border-radius: 7px;
	max-width: 300px;
	padding: 0.02em 0.25em;
	margin-left: auto;
	margin-right: auto;
}
.edit-button.edit-button-for-adding {
	position: static;	
}
.rs-modal-body {
	h2 {
		color: white;	
	}
	p {
		color: white;	
	}
	.delete-button {
		color: red;
		background: none;
	}
}
.rs-modal-footer {
	display: flex;
	justify-content: space-between;
	&::after {
		content: none;
	}
	&::before {
		content: none;
	}
	.delete-button {
		color: red;
		background: none;
	}
	button {
		background-color: #3F8390;
		display: inline-block;
		margin: 0.3em;
		font-family: 'Montserrat';
		font-weight: 600;
		color: white;
		border: none;
		padding: 0.375rem 0.75rem;
		border-radius: 0.25rem;
	}
}

nav.editor-toolbar {
	background-color: #f6b8df;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	z-index: 1000;
	button {
		@extend .btn;
		background-color: white;
		display: inline-block;
		margin: 0.3em;
		font-family: 'Montserrat';
		font-weight: 600;
		font-size: 1rem;
		color: #034b59;
		border: none;
		border-radius: 32px;
		font-weight: normal;
	}
	.save-state-label {
		text-align: right;
		font-size: 0.7em;
		position: absolute;
		top: 0;
		right: 0.2em;
		transform: translateY(-100%);
		&.save-state-label-saving {
			color: black;	
		}
		&.save-state-label-saved {
			color: green;	
		}
		&.save-state-label-error {
			color: red;	
		}
	}
	button.change-url-button {
		i {
			color: #f52ad4;	
		}
	}
}

.video-edit-panel {
	.video-preview {
		height: 15em;	
	}
}

// General bio styles
.bio {
	background-color:white;
}

.theme-gallery {
	.theme-gallery-header {
		.theme-gallery-header-buttons {
			display: flex;
			justify-content: flex-end;
			button {
				@extend .btn;
				margin-left: 1em;
				margin-right: 1em;
				background-color: #3F8390;
				font-family: 'Montserrat';
				font-weight: 600;
				color: white;
				margin-top: auto;
				margin-bottom: auto;
				float: right;
				clear: right;
				margin-top: 0.4em;
				margin-bottom: 0.2em;
			}
		}
		h2 {
			margin-left: 1em;
			margin-right: 1em;
			text-align: center;
			font-family: 'Montserrat';
			font-size: 90%;
		}
	}
	.theme-gallery-bios {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.theme-gallery-bio-container {
			h3 {
				font-family: 'Montserrat';	
				text-align: center;
				position: relative;
				@include desktop-version() {
					font-size: 20px;
				}
				@include mobile-version() {
					font-size: 14px;
				}
				font-size: 80%;
				.theme-gallery-availability-badge {
					display: inline-block;
					margin-left: 0.6em;
					background-color: #f52ad4;
					color: white;
					font-size: 80%;
					padding: 0.2em;
					border-radius: 0.2em;
				}
			}
			@include desktop-version() {
				padding: 1em;
				width: 33%;
				height: 80vh;
			}
			@include mobile-version() {
				font-size: 7px;
				width: 55vw;	
				height: 60vh;
				margin-bottom: 2em;
			}
			cursor: pointer;
			margin-bottom: 4em;
			padding-bottom: 20px; // Account for the phone border
			
			.bio {
				@include desktop-version() {
					font-size: 10px;
				}
				@include mobile-version() {
					font-size: 7px;
				}
				width: 100%;
				height: 100%;
				overflow: hidden;
				// Phone/tablet device border
				border: 20px solid black;
				border-radius: 20px;
				box-shadow: 10px 10px 29px 5px rgba(0,0,0,0.32);
			}
		}
	}

}

.intro-popup {
	.rs-modal-content {
		background-color: white;
		
		.rs-btn-primary {
			&:hover {
				background-color: rgba(3,75,89,0.75);	
			}
			background-color: #034b59;
			border-radius: 0;
		}
		p {
			color: black;	
		}
	}
	.edit-button {
		position: static;
		font-size: 1em;
		margin-left: 0;
		margin-right: 0;
		display: inline-block;
	}
}

.question-popup {
	.rs-modal-content {
		background-color: white;
		
		h1 {
			color: black;	
		}
		
		.rs-btn-primary {
			&:hover {
				background-color: rgba(3,75,89,0.75);	
			}
			background-color: #034b59;
			border-radius: 0;
		}
		
		.cancel-button {
			&:hover {
				background: none;
				border: none;				
			}
			background: none;
			border: none;
			color: red;
		}
		
		.edit-panel-field label {
			color: black;	
		}
		
		label.disclaimer {
			opacity: 0.5;	
		}
	}
}

.quality-check-panel {
	.cancel-button {
		&:hover {
			background: none;
			border: none;				
		}
		background: none;
		border: none;
		color: red;
	}
	.edit-button {
		position: relative;
		font-size: 120%;
		display: inline-block;
		margin: 0.3em;
	}
}

.done-popup-panel {
	.rs-modal-content {
		background-color: #282a29;	
	}
	button {
		@extend .btn;
		background-color: #3F8390;
		display: inline-block;
		margin: 0.3em;
		font-family: 'Montserrat';
		font-weight: 600;
		font-size: 1rem;
		color: white;
		border: none;
		&:hover {
			background-color: white;
			color: #3F8390;
		}	
	}
	.done-close-button {
		font-size: 70%;
		i {
			margin-left: 1em;	
			font-size: 200%;
		}
		&:hover {
			background: none;
			border: none;	
			color: white;			
		}
		color: white;
		text-transform: uppercase;
		margin-left: auto;
		display: flex;
		align-items: center;
		background: none;
	}
	h1 {
		font-family: 'Montserrat';	
		font-size: 1.5rem;
	}
	p.done-description {
		margin-bottom: 0;
		text-align: center;	
		font-family: 'Montserrat';
	}
	p.done-body-description {
		font-family: 'Montserrat';
	}
	p.done-success-description {
		margin-bottom: 0;
		font-family: 'Montserrat';
	}
	label.done-field-label {
		font-family: 'Montserrat';
		color: white;
		display: block;
		margin-top: 0.5em;
	}
	span.done-link-preview {
		color: #e4f26e;
		text-decoration: underline;	
	}
	.done-share-button-example {
		color: #f52ad4;	
	}
	h2 {
		margin-top: 2rem;
		font-family: 'Montserrat';
		font-weight: 700;
		color: #72aab2;
		font-size: 1.6rem;
		.done-header-counter, .done-header-checkmark {
			display: inline;
			margin-right: 0.2em;
		}
		.done-header-counter {
			width: 2rem;	
			height: 2rem;	
		}
		.done-header-checkmark {
			width: 1.8rem;	
			height: 1.8rem;	
		}
	}
}


.video-promo-popup {
	h2 {
		text-align: center;
		color: white;	
	}
	button.upgrade-to-pro-button {
		background: linear-gradient(64deg,#f52ad4 0%,#034b59 67%);
	}
}

.server-sync-progress-overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(128, 128, 128, 0.8);
	text-align: center;
	display: flex;
	z-index: 10000;
	justify-content: center;
	align-items: center;
	h1 {
		color: white;	
	}
}

// Overriding rsuite's bounce-in animations because the scaling causes problems with react-easy-crop calling getBoundingClientRect to get the size while the element is still animating.
// Ideally we'd just switch to the rsuite Fade animation, but rsuite doesn't seem to be adding the required .show class to make that work. 
@-webkit-keyframes bounceIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  @keyframes bounceIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  @-webkit-keyframes bounceOut {
	from {
	  opacity: 1;
	}
	to {
	  opacity: 0;
	}
  }
  @keyframes bounceOut {
	from {
	  opacity: 1;
	}
	to {
	  opacity: 0;
	}
  }

// Avoid the focus ring on react-joyride
.react-joyride__tooltip button:focus {
	outline: none;
}
  
// Theme-specific styles
@import "./themes/basic/basic.scss";
@import "./themes/neutral-brush/neutral-brush.scss";
@import "./themes/simple-black-and-white/simple-black-and-white.scss";
@import "./themes/emerald-chevron/emerald-chevron.scss";
@import "./themes/earthy-angle/earthy-angle.scss";
@import "./themes/rainbow-lines/rainbow-lines.scss";
@import "./themes/desert-sunset/desert-sunset.scss";
@import "./themes/burgundy-dreams/burgundy-dreams.scss";
@import "./themes/fresh-ambition/fresh-ambition.scss";
@import "./themes/vibrant-visionary/vibrant-visionary.scss";
@import "./themes/retro-radiance/retro-radiance.scss";
@import "./themes/simply-chic/simply-chic.scss";
@import "./themes/modern-sage/modern-sage.scss";
@import "./themes/neon-glow/neon-glow.scss";
@import "./themes/clean-start/clean-start.scss";
