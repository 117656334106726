.bio.theme-clean-start {
	a.cta-button {
		color: white;
		font-family: 'Open Sans';
		font-size: 107%;
		padding: 0.2em 2em;
		border: 4px solid #ff6117;
		background-color: #c08b89;
	}
	
	.intro-row {
		@include desktop-version() {
			padding-top: 0.845em;
			padding-bottom: 0.845em;	
		}
		background-color: #efe8e4;

		.intro-photo-column {
			.intro-photo {
				border: 4px solid #ff6117;
				padding: 0;
				.intro-photo-image {
					border: 4px solid #ff6117;
					margin: 6px;
					width: calc( 100% - 12px );
				}
			}
		}
		
		.intro-text-column {
			.intro-text {
				@include mobile-version() {
					padding-bottom: 1.3em;	
				}
				h1.intro-text-heading {
					font-family: "Tenor Sans";
					color: #ff6117;
					margin-bottom: 1.1em;
				}
				.intro-text-body {
					font-family: 'Open Sans';
					color: #614536;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		padding-top: 1.3em;
		padding-bottom: 1.3em;
		.links-column {
			.links-link-list-row {
				a.link {
					display: block;
					justify-content: center;
					background-color: transparent;
					color: white;
					font-family: 'Open Sans';
					border: 4px solid #ff6117;
					background-color: #ceaa9a;
					padding: 0.2em 2em;
					align-items: center;
					text-align: center;
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
					&:before {
						background-color: #10395b;	
						padding: 0.2em 0.6em;
					}
					&:after {
						background-color: #3C7894;
						padding: 0.2em 0.6em;
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
			}
		}
	}
	.carousel-row {
		padding-top: 0;
		padding-bottom: 0;
		.carousel-column {
			.swiper-pagination-bullet {
				background-color: #ac4e22;
				opacity: 1;
			}
			.swiper-pagination-bullet-active {
				background-color: #ff6117;
			}
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						padding-top: 1em;	
						padding-bottom: 1em;
						background: #ceaa9a;
						.card-photo-column {
							img {
								border-color: white;
							}
						}
						.card-contents-column {
							h2.card-heading {
								margin-top: 1em;
								font-family: 'Tenor Sans';
								font-size: 1.420em;
								color: #fefefe;
							}
							div.card-body {
								margin-top: 1em;
								font-family: 'Open Sans';
								font-size: 99.787%;
								color: #fefefe;
							}
							a.card-button {
								border: 4px solid #ff6117;
								background-color: #f1e0cc;
								color: #a3502a;
								font-family: 'Open Sans';
								align-items: center;
								padding: 0.2em 2em;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		margin-top: 1.3em;
		background-color: #c2a49d;
		background-size: cover;
		background-position: center;
		.testimonial-photo-column {
			@include mobile-version() {
				padding: 0 1.72em;	
			}
			img {
				border-color: white;
				background-color: white;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Tenor Sans';
				font-size: 1.620em;
				color: white;
			}
			.testimonial-body {
				font-family: 'Open Sans';
				color: white;
			}
			.testimonial-attribution {
				font-family: 'Open Sans';
				color: white;
				font-size: 0.892em;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		background: #EEEEEC;
		padding-top: 1.3em;
		padding-bottom: 1.3em;
		margin-bottom: 0;
		.contact-column {
			@include mobile-version() {
				padding-top: 1em;
				padding-bottom: 1em;
			}
			@include desktop-version() {
				padding-top: 0.2em;
				padding-bottom: 0.2em;
			}
			h3.contact-name {
				font-family: 'Tenor Sans';
				font-size: 1.620em;
				color: #614536;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
						color: #614536;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #ff6117;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #ff6117;
					color: white;
				}
			}
			ul.contact-social-media-list {
				margin-bottom: 0;
				li {
					a {
						i {
							color: #ff6117;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		@include mobile-version() {
			padding: 1em 2.72em;
			padding-top: 0;
			max-width: unset;	
		}
		.contact-accent-photo-wrapper {
			width: fit-content;
			margin: auto;
			border: 4px solid #ff6117;
			padding: 0;
			img.contact-accent-photo {
				border: 4px solid #ff6117;
				margin: 6px;
				width: calc( 100% - 12px );
			}
		}
	}
}

