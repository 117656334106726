$image-max-width: 465px;

.bio.theme-retro-radiance {
	background-color: #F8F4F3;
	a.cta-button {
		background-color: #CCA7A1;
		color: #F8F4F3;
		font-family: 'Rubik';
		font-weight: 400;
		font-size: 107%;
		padding: 0.2em 2em;
		box-shadow: 0px 10px 21px -7px rgba(0, 0, 0, 0.28);
	}
	
	.intro-row {
		.intro-photo-column {
			.intro-photo {
				background-color: #B5A599;
				@include mobile-version() {
					box-shadow: 3px 3px 38px 7px rgba(0,0,0,0.16);
				}
				@include desktop-version() {
					box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.28);	
				}
			}
		}
		
		.intro-text-column {
			.intro-text {
				h1.intro-text-heading {
					font-family: "Breathing";
					font-weight: 400;
					font-size: 1.3em;	
					color: #474B4E;
					margin-bottom: 1.1em;
					position: relative;
				}
				.intro-text-body {
					font-family: 'Rubik';
					color: #474B4E;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		background: url('./links-row-background.jpg');
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
		@include desktop-version() {
			padding-top: 1.5em;
			padding-bottom: 0.6em;
		}
		@include mobile-version() {
			padding-top: 2.5em;
			padding-bottom: 1.7em;
			margin-top: 1em;
		}
		.links-column {
			.links-link-list-row {
				a.link {
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
					box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.3);
					background-color: #B5A599;
					display: block;
					position: relative;
					color: #F8F4F3;
					font-family: 'Rubik';
					font-weight: 400;
					text-align: center;
					padding: 0.2em 0.5em;
				}
				a.link:first-of-type {
					margin-top: 0;
				}
			}
		}
	}
	.carousel-row {
		@include desktop-version() {
			margin-top: 1em;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		.carousel-column {
			.swiper-pagination-bullet {
				opacity: 1;
				background-color: #B5A599;	
			}
			.swiper-pagination-bullet-active {
				background-color: #474B4E;
			}
			
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						.card-photo-column {
							img {
								border-color: #B5A599;
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 1.5em;
							}
							position: relative;
							h2.card-heading {
								position: relative;
								font-family: 'Breathing';
								font-size: 1.2em;
								color: #474B4E;
								margin-top: 1.25em;	
							}
							div.card-body {
								font-family: 'Rubik';
								color: #474B4E;
							}
							a.card-button {
								background-color: #CCA7A1;
								color: #F8F4F3;
								font-family: 'Rubik';
								align-items: center;
								padding: 0.3em 2em;
								margin-left: 0;
								box-shadow: 0px 10px 21px -7px rgba(0, 0, 0, 0.28);
								@include mobile-version() {
									margin-bottom: 1em;
								}
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		.testimonial-photo-column {
			img.testimonial-photo {
				border-color: #B5A599;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				position: relative;
				font-family: 'Breathing';
				color: #474B4E;
				@include mobile-version() {
					margin-top: 1em;	
				}
			}
			.testimonial-body {
				font-family: 'Rubik';
				color: #474B4E;
			}
			.testimonial-attribution {
				font-family: 'Rubik';
				color: #474B4E;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		justify-content: center;
		.contact-column {
			h3.contact-name {
				position: relative;
				font-family: 'Breathing';
				color: #474B4E;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Rubik';
						color: #474B4E;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #CCA7A1;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #CCA7A1;
					color: white;
					padding: 0.2em 2em;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: #CCA7A1;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		img.contact-accent-photo {
			border-color: #B5A599;
		}
	}
}