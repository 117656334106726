$image-max-width: 465px;

.bio.theme-simply-chic {
	background-color: white;
	a.cta-button {
		color: #424242;
		font-family: 'EB Garamond';
		font-weight: 600;
		font-style: italic;
		text-decoration: underline !important;
		font-size: 135%;
		padding: 0.2em 2em;
	}
	.video-display-popup {
		a.cta-button {
			color: #424242;
			background-color: white;
			border: 2px solid #cdb19d;
			text-decoration: none !important;
			font-size: 85%;
		}
	}
	
	.intro-row {
		.intro-photo-column {
			.intro-photo {
				padding: 0;
				img.intro-photo-image {
					background-image: url('./intro-photo-background.png');
					background-size: cover;
					padding: 4.9%;
				}
			}
		}
		
		.intro-text-column {
			.intro-text {
				h1.intro-text-heading {
					font-family: "EB Garamond";
					font-weight: 600;
					font-size: 1.7em;	
					color: #cdb19d;
					font-style: italic;
					margin-bottom: 1.1em;
					position: relative;
				}
				.intro-text-body {
					font-family: 'EB Garamond';
					color: #424242;
					font-size: 120%;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		background-color: #f6f3ed;
		background-image: url('./section-background-top.png'), url('./section-background-bottom.png');
		background-position: top, bottom;
		background-repeat: repeat-x;
		background-size: auto 50px, auto 42px;
		padding-top: 60px;
		padding-bottom: 52px;
		margin-top: 1em;
		margin-bottom: 2em;
		
		.links-column {
			.links-link-list-row {
				a.link {
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 86%;
					}
					display: block;
					position: relative;
					color: #424242;
					font-family: 'EB Garamond';
					font-weight: 600;
					text-align: center;
					font-style: italic;
					border: 2px solid #cdb19d;
					padding: 0.2em 0.5em;
					margin-bottom: 2em;
				}
				a.link:first-of-type {
					margin-top: 0;
				}
				a.link:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
	.carousel-row {
		@include desktop-version() {
			margin-top: 1em;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		.carousel-column {
			.swiper-container {
				overflow: visible;
			}
			.swiper-pagination-bullet {
				opacity: 1;
				background-color: #B5A599;	
			}
			.swiper-pagination-bullet-active {
				background-color: #474B4E;
			}
			
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						.card-photo-column {
							div {
								display: inline-block;
								position: relative;
								&::before {
									pointer-events: none;
									content: "";
									position: absolute;
									width: 100%;
									height: 100%;
									background: url('./tape.png');
									background-position: top center;
									background-repeat: no-repeat;
									background-size: 50%;
									top: -10%;
									z-index: 1;
								}
								img {
									border-color: #f6f3ed;
								}
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 1.5em;
							}
							position: relative;
							h2.card-heading {
								position: relative;
								font-family: 'EB Garamond';
								font-size: 1.6em;
								color: #cdb19d;
								font-weight: 600;
								font-style: italic;
								margin-top: 0.8em;	
							}
							div.card-body {
								font-family: 'EB Garamond';
								color: #474B4E;
								font-size: 110%;
							}
							a.card-button {
								background: none;
								color: #424242;
								font-family: 'EB Garamond';
								font-weight: 600;
								font-style: italic;
								font-size: 120%;
								text-decoration: underline;
								align-items: center;
								padding: 0.3em 2em;
								margin-left: 0;
								@include mobile-version() {
									margin-bottom: 1em;
								}
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background-color: #f6f3ed;
		background-image: url('./section-background-top.png'), url('./section-background-bottom.png');
		background-position: top, bottom;
		background-repeat: repeat-x;
		background-size: auto 50px, auto 42px;
		padding-top: 60px;
		padding-bottom: 52px;
		margin-top: 1em;
		margin-bottom: 1em;
		
		.testimonial-photo-column {
			img.testimonial-photo {
				border: none;
				background-image: url('./testimonial-photo-background.png');
				background-size: cover;
				padding: 4.9%;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				position: relative;
				font-family: 'EB Garamond';
				color: #cdb19d;
				font-weight: 600;
				font-size: 1.7em;
				font-style: italic;
				@include mobile-version() {
					margin-top: 1em;	
				}
			}
			.testimonial-body {
				font-family: 'EB Garamond';
				font-weight: 600;
				font-size: 1.2em;
				line-height: 1.1;
				color: #424242;
			}
			.testimonial-attribution {
				font-family: 'EB Garamond';
				font-weight: 600;
				font-size: 1em;
				color: #424242
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		justify-content: center;
		.contact-column {
			h3.contact-name {
				position: relative;
				font-family: 'EB Garamond';
				color: #cdb19d;
				font-weight: 600;
				font-size: 1.7em;
				font-style: italic;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'EB Garamond';
						color: #424242;
						font-weight: bold;
						font-size: 1.2em;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #cdb19d;
						font-size: 170%;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #cdb19d;
					color: white;
					padding: 0.2em 2em;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: #424242;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		img.contact-accent-photo {
			border-color: #f6f3ed;
		}
	}
}