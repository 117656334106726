.bio.theme-neutral-brush {
	.cta-button {
		text-decoration: none;
		font-family: 'Playfair Display';
		font-size: 106.610%;
		color: #50504e;
		padding: 0.25em 0.5em;
		background-color: #e8dbd0;
		box-shadow: 1px 1px 0px 0px #834c4b, 2px 2px 0px 0px #834c4b, 3px 3px 0px 0px #834c4b, 4px 4px 0px 0px #834c4b, 5px 5px 0px 0px #834c4b;
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		margin-bottom: 7px !important;
	}
	.intro-row {
		background: none;
		.intro-photo {
			position: relative;
			background: none;
			&::before {
				pointer-events: none;
				content: "";
				position: absolute;
				background: url('./intro-photo-brush-stroke.png');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 1;
				top: -0.6em;
				left: -0.6em;
				width: 30%;
				height: calc(30% * 0.873); // Keep aspect ratio
				@include mobile-version() {
					width: 25%;
					height: calc(25% * 0.873); // Keep aspect ratio
				}
			}
			&::after {
				content: "";
				position: absolute;
				background: url('./intro-photo-brush-stroke.png');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 1;
				bottom: -0.4em;
				right: -0.4em;
				transform: translate(20%, 20%);
				width: 24%;
				height: calc(24% * 0.873); // Keep aspect ratio
				@include mobile-version() {
					width: 22%;
					height: calc(22% * 0.873); // Keep aspect ratio
				}
			}
		}
		.intro-text {
			h1.intro-text-heading {
				color: #834c4b;	
				font-family: 'Playfair Display';
			}
			.intro-text-body {
				font-family: 'Cooper Hewitt Book';
			}
		}
	}
	.links-row {
		background-color: #f8f4f1;
		.links-column {
			.links-link-list-row {
				a.link {
					position: relative;
					text-decoration: none;
					font-family: 'Playfair Display';
					color: #50504e;
					margin-top: 1em;
					z-index: 0;
					border: none;
					text-align: center;
					display: inline;
					padding: 0.25em 0.5em;
					background-color: #f3eeea;
					box-shadow: 1px 1px 0px 0px #E6DCD1, 2px 2px 0px 0px #E6DCD1, 3px 3px 0px 0px #E6DCD1, 4px 4px 0px 0px #E6DCD1, 5px 5px 0px 0px #E6DCD1;
					user-select: none;
					-webkit-user-select: none;
					touch-action: manipulation;
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
					.edit-button {
						margin-left: 0.3em;
					}
				}
				a.link:first-of-type {
					margin-top: 0;	
				}
				a.link:last-of-type {
					margin-bottom: 7px;	
				}
			}
		}
	}
	.carousel-row {
		.carousel-column {
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 23px;
						height: auto;
					}
				}
				.swiper-slide {
					.carousel-card {
						.card-photo-column {
							img {
								border-color: #50504E;	
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 0;	
							}
							h2.card-heading {
								font-family: 'Playfair Display';
								color: #834c4b;
								position: relative;
								z-index: 0;
								margin-top: 0.4em;
								margin-bottom: 0.8em;
								&::before {
									pointer-events: none;
									content: "";
									position: absolute;
									max-width: 9em;
									width: 100%;
									height: 100%;
									background: url('./header-brush-stroke.png');
									background-position: center;
									background-repeat: no-repeat;
									background-size: contain;
									z-index: -1;
									top: 100%;
									left: 50%;
									transform: translateX(-50%) translateY(-50%);
								}
							}
							div.card-body {
								font-family: 'Cooper Hewitt Book';
								color: #50504e;
							}
							a.card-button {
								position: relative;
								text-decoration: none;
								font-family: 'Playfair Display';
								color: #50504e;
								margin-bottom: 7px; // Allow for the shadow
								z-index: 0;
								border: none;
								text-align: center;
								display: inline-block;
								padding: 0.25em 0.5em;
								background-color: #f3eeea;
								box-shadow: 1px 1px 0px 0px #E6DCD1, 2px 2px 0px 0px #E6DCD1, 3px 3px 0px 0px #E6DCD1, 4px 4px 0px 0px #E6DCD1, 5px 5px 0px 0px #E6DCD1;
								user-select: none;
								-webkit-user-select: none;
								touch-action: manipulation;
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background: #E8DBD0;
		.testimonial-photo-column {
			position: relative;
			.testimonial-photo-wrapper {

			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Playfair Display';
				color: #834c4b;
				position: relative;
				z-index: 0;
				margin-bottom: 0.8em;
				&::before {
					pointer-events: none;
					content: "";
					position: absolute;
					max-width: 9em;
					width: 100%;
					height: 100%;
					background: url('./testimonial-header-brush-stroke.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					z-index: -1;
					top: 100%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}
			.testimonial-body {
				font-family: 'Cooper Hewitt Book';
				color: #50504e;
			}
			.testimonial-attribution {
				font-family: 'Cooper Hewitt Book';
				color: #9d928a;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		margin-top: 0;
		background-color: white;
		@include desktop-version() {
			padding-top: 1em;
			padding-bottom: 1em;
		}
		.contact-column {
			@include mobile-version() {
				padding-top: 1em;
				padding-bottom: 1em;
			}
			h3.contact-name {
				font-family: 'Cooper Hewitt Bold';
				color: #50504e;
			}
			ul.contact-list {
				li {
					font-family: 'Cooper Hewitt Book';
					color: #50504e;
					line-height: 140%;
				}
				margin-bottom: 0.2em;
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #834c4b;
					}
				}
			}
			ul.contact-social-media-list {
				padding-top: 0.5em;
				li {
					a {
						i {
							font-size: 1.1em;
							margin-left: 0.3em;
							margin-right: 0.3em;
							color: #834c4b;
						}	
					} 
					.edit-button {
						font-size: 1.7em;
					}
				}
			}
		}
		div.contact-vcard {
			button.contact-vcard-open-button {
				background-color: #834c4b;
				color: white;
			}
		}
		.contact-accent-column {
			img.contact-accent-photo {
				width: 100%;
				height: auto;
				border-color: #50504E;
			}
		}
	}
}
