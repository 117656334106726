.bio.theme-rainbow-lines {
	a.cta-button {
		background-color: #a00e4f;
		color: white;
		font-family: 'Open Sans';
	}
	
	.intro-row {
		.intro-photo-column {
			.intro-photo {
				position: relative; // Set up a context for the stripes to appear behind it
				z-index: 2;
			}
		}
		.intro-text-column {
			@include mobile-version() {
				position: relative;
				z-index: 1;
				&::before {
					pointer-events: none;
					content: "";
					position: absolute;
					background: url('./stripes-wide-transparent.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 625px 81px;
					z-index: -1;
					left: 0;
					top: 10%;
					width: 100vw;
					height: 100%;
				}
			}
			.intro-text {
				background-color: #e8d5d4;					
				position: relative;
				@include desktop-version() {
					&::before {
						pointer-events: none;
						content: "";
						position: absolute;
						background: url('./stripes-wide-transparent.png');
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						z-index: 1;
						right: 101%;
						width: 180%;
						height: 100%;
						top: 0;
					}
				}
				
				h1.intro-text-heading {
					font-family: "DM Serif Display";
					color: #a00e4f;
					margin-bottom: 1.1em;
				}
				.intro-text-body {
					font-family: 'Open Sans';
					color: #471708;
				}
				.edit-button {
					color: white;
					border-color: white;
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		.links-column {
			.links-link-list-row {
				@include mobile-version() {
					padding-top: 0.5em;	
					max-width: 90%;
				}
				a.link {
					display: flex;
					position: relative;
					background-color: #e8d5d4;
					color: #471708;
					font-family: 'Open Sans';
					align-items: center;
					padding: 0.4em 0.8em 0.4em 2em;
					margin-bottom: 2em;
					img.link-icon {
						height: 2.8em;
						width: auto;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
			}
		}
	}
	.carousel-row {
		.carousel-column {
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 40px;
						height: auto;
						@include mobile-version() {
							margin-top: -4em;
						}
					}
				}
				.swiper-slide {
					.carousel-card {
						.card-photo-column {

						}
						.card-contents-column {
							padding-bottom: 2em;	
							background: #e8d5d4;
							padding-top: 0.5em;
							margin-top: 1em;
							h2.card-heading {
								margin-top: 1em;
								font-family: 'DM Serif Display';
								color: #a00e4f;
							}
							div.card-body {
								margin-top: 1em;
								font-family: 'Open Sans';
								color: #470718;
							}
							a.card-button {
								background-color: #a00e4f;
								color: white;
								font-family: 'Open Sans';
								align-items: center;
								padding: 0.2em 0.6em;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		@include desktop-version() {
			background-color: #e8d5d4;
		}
		.testimonial-photo-column {
			@include mobile-version() {
				background: url('./stripes-extra-wide.png');
				background-position: center;
				background-repeat: repeat-x;
				background-size: 1913px 86px;
				z-index: 2;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'DM Serif Display';
				color: #a00e4f;
			}
			.testimonial-body {
				font-family: 'Open Sans';
				color: #470718;
			}
			.testimonial-attribution {
				font-family: 'Open Sans';
				color: #470718;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		padding-bottom: 1em;
		@include desktop-version() {
			background: url('./stripes-extra-wide.png');
			background-position: center;
			background-repeat: repeat-x;
			background-size: 2550px 114px;
		}
		.contact-column {
			@include mobile-version() {
				background-color: #e8d5d4;
				position: relative;
				z-index: 1;
				padding-top: 1em;
				padding-bottom: 1em;
			}
			background-color: #e8d5d4;

			h3.contact-name {
				font-family: 'Open Sans';
				color: #471708;
				font-weight: 600;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
						color: #471708;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #a00e4f;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #a00e4f;
					color: white;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: #a00e4f;
						}	
					} 
				}
			}
		}
		.contact-accent-column {
			@include mobile-version() {
				z-index: 2;
			}
			.contact-accent-photo-container {
				@include desktop-version() {
					display: flex;
				}
			}
		}
	}
}