.bio.theme-emerald-chevron {
	a.cta-button {
		background-color: #bc9337;
		color: white;
		font-family: 'Oswald';
		img {
			height: 1.2em;
			padding-left: 0.4em;
			width: auto;
		}
	}
	.intro-row {
		background: linear-gradient(to right, white, white 25%, #244441 25%);
		margin-bottom: 1em;
		
		@include mobile-version() {
			background: linear-gradient(to bottom, white, white 35%, #244441 35%);
			justify-content: center;
		}
		
		.intro-photo-column {
			.intro-photo {
			}
		}
		
		.intro-text-column {
			.intro-text {
				background-color: #244441;
				h1.intro-text-heading {
					font-family: "Oswald";
					color: #bc9337;
					text-align: left;
					margin-bottom: 1.1em;
				}
				.intro-text-body {
					font-family: 'Open Sans';
					color: white;
					text-align: left;
				}
				.edit-button {
					color: white;
					border-color: white;
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		background-color: #244441;
		background: url('./links-row-background.png');
		background-size: 864px 864px;
		margin-bottom: 1em;	
		.links-column {
			.edit-button {
				color: white;
			}
			.links-link-list-row {
				a.link {
					background-color: #bc9337;
					color: white;
					font-family: 'Oswald';
					align-items: center;
					padding: 0.2em 0.6em;
					img {
						height: 1.2em;
						width: auto;
						margin-left: 0.4em;
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
			}
		}
	}
	.carousel-row {
		background-color: #244441;
		padding-top: 1em;
		padding-bottom: 1em;
		@include desktop-version() {
			margin-bottom: 1em;	
		}
		.carousel-column {
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 40px;
						height: auto;
						@include mobile-version() {
							margin-top: -4em;
						}
					}
				}
				.swiper-slide {
					.carousel-card {
						color: #244441;
						.card-photo-column {
							img {
								border: none;	
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 1em;
							}
							padding-top: 0.5em;
							background-color: #f2f4f4;
							h2.card-heading {
								margin-top: 1em;
								font-family: 'Oswald';
								font-size: 1.420em;
							}
							div.card-body {
								font-family: 'Open Sans';
							}
							a.card-button {
								background-color: #bc9337;
								color: white;
								font-family: 'Oswald';
								align-items: center;
								padding: 0.2em 0.6em;
								margin-left: 0;
								img {
									height: 1.2em;
									padding-left: 0.4em;
									width: auto;
								}
							}
						}
					}
				}
			}
			.carousel-add-row {
				.edit-button {
					color: white;
				}
			}
		}
	}
	.testimonial-row {
		background: url('./testimonial-background.png');
		.testimonial-photo-column {

		}
		.testimonial-contents-column {
			.testimonial-contents {
				padding: 1em;
				background-color: white;
				h2.testimonial-heading {
					font-family: 'Oswald';
					font-size: 1.620em;
					color: #bc9337;
					font-weight: normal;
				}
				.testimonial-body {
					font-family: 'Open Sans';
				}
				.testimonial-attribution {
					font-family: 'Open Sans';
				}
				.edit-button {
				}	
			}	
		}
	}

	.contact-row {
		.contact-column {
			h3.contact-name {
				font-family: 'Open Sans';
				color: #244441;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
						color: #244441;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #bc9337;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #bc9337;
					color: white;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: #bc9337;
						}	
					} 
				}
			}
		}
		.contact-accent-column {

		}
	}
}