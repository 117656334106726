.bio.theme-neon-glow {
	.neon-glow-button {
		display: block;
		position: relative;
		text-align: center;
		color: #242424;
		background-color: white;
		border: 2px solid #3F8390;
		border-radius: 6px;
		padding-top: 0.2em;
		padding-bottom: 0.2em;
		padding-left: 2em;
		padding-right: 2em;
		font-family: 'Oswald';
		&::after {
			pointer-events: none;
			content: "";
			position: absolute;
			top: 0;
			height: 100%;
			width: 0.6em;
			right: 0.5em;
			background: url('./link-icon.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
	
	a.cta-button {
		font-size: 107%;
		@extend .neon-glow-button;
	}
	
	.intro-row {
		@include desktop-version() {
			padding-bottom: 0.845em;	
		}
		background-color: #242424;

		.intro-photo-column {
			.intro-photo {
				@include mobile-version() {
					margin-top: 1.5em;	
				}
				
				background-color: #FFFFFF;
			}
		}
				
		.intro-text-column {
			.intro-text {
				@include mobile-version() {
					padding-top: 2em;
					padding-bottom: 2em;	
				}
				h1.intro-text-heading {
					font-family: "Prata";
					color: #E4F26E;
					margin-bottom: 1.1em;
				}
				.intro-text-body {
					font-family: 'Lato';
					color: white;
				}
			}
		}
	}
	.links-row {
		padding-top: 1.3em;
		padding-bottom: 1.3em;
		background-color: #F4F1ED;
		.links-column {
			.links-link-list-row {
				a.link {
					@include desktop-version() {
						font-size: 74.9%;	
					}
					@include mobile-version() {
						font-size: 107%;
					}
					@extend .neon-glow-button;
				}
			}
		}
	}
	.carousel-row {
		padding-top: 0;
		padding-bottom: 1em;
		background-color: #F4F1ED;
		.carousel-column {
			.swiper-pagination-bullet {
				background-color: #ffffff;
				opacity: 1;
			}
			.swiper-pagination-bullet-active {
				background-color: #3F8390;
			}
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						padding-top: 1em;	
						padding-bottom: 1em;
						.card-photo-column {
							img {
								border-color: #242424;
							}
						}
						.card-contents-column {
							h2.card-heading {
								@include desktop-version() {
									margin-top: 0.8em;
								}
								@include mobile-version() {
									margin-top: 1.6em;
								}
								font-family: 'Prata';
								font-size: 1.420em;
								color: #007083;
							}
							div.card-body {
								margin-top: 0.5em;
								font-family: 'Lato';
								font-size: 99.787%;
								color: #242424;
							}
							a.card-button {
								@extend .neon-glow-button;
								display: inline-block;
								font-size: 107%;
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		padding-top: 2em;
		padding-bottom: 2em;
		background: url('./testimonial-background.jpg');
		background-size: cover;
		background-position: center;
		.testimonial-photo-column {
			@include mobile-version() {
				padding: 0 1.72em;	
			}
			img {
				border-color: #E4F26E;
				background-color: #E4F26E;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Prata';
				font-size: 1.620em;
				color: #E4F26E;
			}
			.testimonial-body {
				font-family: 'Lato';
				color: white;
			}
			.testimonial-attribution {
				font-family: 'Lato';
				color: white;
				font-size: 0.892em;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		background: #F4F1ED;
		padding-top: 2em;
		padding-bottom: 2em;
		margin-bottom: 0;
		.contact-column {
			@include mobile-version() {
				padding-top: 1em;
				padding-bottom: 1em;
			}
			@include desktop-version() {
				padding-top: 0.2em;
				padding-bottom: 0.2em;
			}
			h3.contact-name {
				font-family: 'Prata';
				font-size: 1.620em;
				color: #3F8390;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Lato';
						color: #242424;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #3F8390;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					@extend .neon-glow-button;
					display: inline-block;
					min-width: 68%;
					padding-left: 1em;
					padding-right: 1em;
					padding-top: 0.01em;
					padding-bottom: 0.01em;
					
					&::after {
						display: none;	
					}
				}
			}
			ul.contact-social-media-list {
				margin-bottom: 0;
				li {
					a {
						i {
							color: #3F8390;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		img.contact-accent-photo {
			border-color: #242424;
		}
	}
}

