$image-max-width: 465px;

.bio.theme-vibrant-visionary {
	.shadow-button {
		&:after{
			pointer-events: none;
			content: "";
			position: absolute;
			max-width: 100%;
			width: 210px;
			height: 40px;
			background: url('./button-shadow.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -70%);
			z-index: -1;
		}
	}
	a.cta-button {
		@extend .shadow-button;
		position: relative;
		background-color: #d1d0d0;
		border-radius: 7px;
		color: black;
		font-family: 'Lato';
		font-weight: 400;
		padding: 0.35em 3em;
	}
	
	.intro-row {
		.intro-photo-column {
			.intro-photo {
				background-color: #383e48;
				@include mobile-version() {
					box-shadow: 3px 3px 38px 7px rgba(0,0,0,0.16);
				}
				@include desktop-version() {
					box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.28);	
				}
			}
		}
		
		.intro-text-column {
			.intro-text {
				z-index: 1; // Support our button shadow appearing behind the button
				h1.intro-text-heading {
					font-family: "Lato";
					font-weight: 400;
					font-size: 1.3em;	
					color: black;
					margin-bottom: 1.1em;
					position: relative;
				}
				.intro-text-body {
					font-family: 'Lato';
					color: black;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		@include mobile-version() {
			margin-top: 2em;
		}
		.links-column {
			.links-link-list-row {
				@include mobile-version() {
					margin-bottom: 0.7em;
					min-width: 80%;
				}
				@include desktop-version() {
					min-width: 12em;	
				}
				position: relative;
				z-index: 1;
				a.link {
					@extend .shadow-button;
					@include desktop-version() {
						font-size: 67%;
					}
					position: relative;
					border-radius: 7px;
					background-color: #545454;
					display: block;
					position: relative;
					color: white;
					font-family: 'Lato';
					font-weight: 400;
					text-align: center;
					padding: 0.35em 0.6em;
					margin-top: 0.7em;
					margin-bottom: 0.7em;
				}
				a.link:first-of-type {
					margin-top: 0;
				}
			}
		}
	}
	.carousel-row {
		@include desktop-version() {
			margin-top: 1em;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		.carousel-column {
			.swiper-pagination-bullet {
				opacity: 1;
				background-color: #D1D0D0;	
			}
			.swiper-pagination-bullet-active {
				background-color: black;
			}
			
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						.card-photo-column {
							img {
								border-color: #383e48;
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 1.5em;
							}
							position: relative;
							z-index: 1;
							h2.card-heading {
								position: relative;
								font-family: 'Lato';
								font-size: 1.2em;
								color: black;
								
								@include desktop-version() {
									margin-top: 0.6em;	
								}
								@include mobile-version() {
									margin-top: 1.25em;	
								}
							}
							div.card-body {
								font-family: 'Lato';
								color: black;
							}
							a.card-button {
								@extend .shadow-button;
								position: relative;
								border-radius: 7px;
								background-color: #D1D0D0;
								color: black;
								font-family: 'Lato';
								align-items: center;
								padding: 0.3em 2em;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background:  #545454;	
		.testimonial-photo-column {
			img {

			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				position: relative;
				font-family: 'Lato';
				color: white;
			}
			.testimonial-body {
				font-family: 'Lato';
				color: white;
			}
			.testimonial-attribution {
				font-family: 'Lato';
				color: white;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		justify-content: center;
		.contact-column {
			h3.contact-name {
				position: relative;
				font-family: 'Lato';
				color: black;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Lato';
						color: black;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #545454;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #545454;
					color: white;
					padding: 0.2em 2em;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: #545454;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		img.contact-accent-photo {
			border-color: #383E48;
		}
	}
}