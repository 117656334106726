.bio.theme-earthy-angle {
	a.cta-button {
		background-color: #EAEBEA;
		color: #857976;
		font-family: 'Oswald';
		border-radius: 12px;
		font-size: 107%;
		img {
			width: 1.38em;
			height: 1.2em;
			margin-left: 0.4em;
		}
	}
	
	.intro-row {
		@include desktop-version() {
			padding-top: 0.65em;
			padding-bottom: 0.65em;	
		}
		background-color: #857976;

		.intro-photo-column {
			.intro-photo {
				background: url('./paper-background.png');
				background-size: 100% 100%;
				filter: drop-shadow(3px 1px 5px rgba(0,0,0,0.3));
			}
		}
		
		.intro-text-column {
			.intro-text {
				h1.intro-text-heading {
					font-family: "Oswald";
					color: #d3b4b1;
					margin-bottom: 1.1em;
				}
				.intro-text-body {
					font-family: 'Roboto';
					color: white;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		.links-column {
			.links-link-list-row {
				a.link {
					background-color: #857976;
					color: white;
					font-family: 'Oswald';
					align-items: center;
					padding: 0.2em 0.6em;
					border-radius: 12px;
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
					img {
						width: 1.379577092em;
						height: 1.2em;
						margin-left: 0.4em;
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
			}
		}
	}
	.carousel-row {
		background-color: #857976;
		padding-top: 1em;
		padding-bottom: 1em;
		.carousel-column {
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 40px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						padding-top: 1em;	
						padding-bottom: 1em;
						background: #eaebea;
						.card-photo-column {
							img {
								border-color: white;
							}
						}
						.card-contents-column {
							h2.card-heading {
								margin-top: 1em;
								font-family: 'Oswald';
								font-size: 1.420em;
								color: #857976;
							}
							div.card-body {
								margin-top: 1em;
								font-family: 'Roboto';
								font-size: 99.787%;
								color: #857976;
							}
							a.card-button {
								background-color: #857976;
								color: white;
								font-family: 'Oswald';
								align-items: center;
								padding: 0.2em 0.6em;
								margin-left: 0;
								border-radius: 12px;
								img {
									width: 1.379577092em;
									height: 1.2em;
									margin-left: .4em;
								}
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background:  white;	
		.testimonial-photo-column {
			img {
				border-color: #EAEBEA;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Oswald';
				font-size: 1.620em;
				color: #d3b4b1;
			}
			.testimonial-body {
				font-family: 'Roboto';
				color: #857976;
			}
			.testimonial-attribution {
				font-family: 'Roboto';
				color: #857976;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		background: #EAEBEA;
		padding-top: 1em;
		padding-bottom: 1em;
		margin-bottom: 0;
		.contact-column {
			@include mobile-version() {
				padding-top: 1em;
				padding-bottom: 1em;
				background-color: #eaebea;
			}
			@include desktop-version() {
				padding-top: 0.2em;
				padding-bottom: 0.2em;
				background-color: #eaebea;
			}
			h3.contact-name {
				font-family: 'Oswald';
				font-size: 1.620em;
				color: #857976;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
						color: #857976;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #D3B4B1;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #D3B4B1;
					color: white;
				}
			}
			ul.contact-social-media-list {
				margin-bottom: 0;
				li {
					a {
						i {
							color: #D3B4B1;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		img.contact-accent-photo {
			border-color: white;
		}
	}
}