.bio.theme-basic {
	a.cta-button {
		background-color: black;
		color: white;
		font-family: 'Oswald';
		border-radius: 12px;
		font-size: 102%;
	}
	
	.intro-row {
		background: black;
		@include desktop-version() {
			padding-top: 0.65em;
			padding-bottom: 0.65em;	
		}

		.intro-photo-column {
			.intro-photo {
				@include desktop-version() {
					margin-right: 1em;
					margin-left: auto;
				}
				background-color: #ebebeb;
			}
		}
		.intro-text-column {
			.intro-text {
				@include mobile-version() {
					margin-bottom: 1em;	
					margin-top: 0.5em;
				}
				background-color: #ebebeb;
				h1.intro-text-heading {
					font-family: "Oswald";
				}
				.intro-text-body {
					font-family: 'Roboto';
				}
			}
		}
	}
	.links-row {
		.links-column {
			.links-link-list-row {
				@include desktop-version() {
					margin-bottom: 0.2em;	
				}
				@include mobile-version() {
					margin-bottom: 0.4em;	
				}
				a.link {
					.edit-button {
						margin-left: 0;
						margin-right: 0;	
					}
					color: black;
					font-family: 'Oswald';
					border-bottom: 1px solid black;
					margin-bottom: 1.1em;
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
				.add-link-button {
					color: #575757;
				}
			}
		}
	}

	.carousel-row {
		margin-bottom: 0;
		background-color: #efefef;
		.carousel-column {
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 40px;
						height: auto;
					}
				}
				.swiper-slide {
					.carousel-card {
						.card-photo-column {
							img {
								border-color: white;
							}
						}
						.card-contents-column {
							h2.card-heading {
								font-family: 'Oswald';
								font-size: 1.420em;
								margin-top: 0.5em;
							}
							div.card-body {
								font-family: 'Roboto';
								font-size: 99.787%;
							}
							a.card-button {
								background-color: black;
								color: white;
								font-family: 'Oswald';
								border-radius: 12px;
								align-items: center;
								padding: 0.2em 0.6em;
								img {
									height: 1.2em;
									padding-left: 0.5em;
									width: auto;
								}
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background-color: black;
		color: white;
		.testimonial-photo-column {
			img {
				border-color: #efefef;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Oswald';
				font-size: 1.620em;
				font-weight: normal;
			}
			.testimonial-body {
				font-family: 'Roboto';
			}
			.testimonial-attribution {
				font-family: 'Roboto';
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		margin-top: 0;
		margin-bottom: 0;
	    padding-top: 1em;
	   	padding-bottom: 1em;
		background-color: #efefef;
		.contact-column {
			background-color: #efefef;
			h3.contact-name {
				font-family: 'Oswald';
				font-size: 1.620em;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: black;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: black;
					color: white;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: black;
						}	
					} 
				}
			}
		}
		.contact-accent-column {
			img.contact-accent-photo {
				border-color: black;
			}
		}
	}
}