.bio.theme-fresh-ambition {
	.flip-button {
		position: relative;
		perspective: 1800px;
		&:hover{
			&:before{
				opacity: 0;
				transform: translateY(-50%) rotateX(90deg);
			}
			&:after{
				opacity: 1;
				transform: translateY(0) rotateX(0);
			}
		}
		&:before{
			top: 0;
			left: 0;
			opacity: 1;
			display: block;
			transition: 0.3s;
			position: relative;
			content: attr(data-front);
			transform: translateY(0) rotateX(0);
		}	
		&:after{
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
			display: block;
			transition: 0.3s;
			position: absolute;
			content: attr(data-back);
			transform: translateY(50%) rotateX(-90deg);
		}
	}
	
	a.cta-button {
		@extend .flip-button;
		color: white;
		font-family: 'Roboto';
		font-size: 107%;
		padding: 0;
		
		&:before {
			background-color: #3c7894;	
			padding: 0.2em 2em;
		}
		&:after {
			background-color: #10395B;
			padding: 0.2em 2em;
		}
		.edit-button {
			z-index: 2; // Keep the edit button on top of the flip animation	
		}
	}
	
	.intro-row {
		@include desktop-version() {
			padding-top: 0.845em;
			padding-bottom: 0.845em;	
		}
		background-color: #eeeeec;

		.intro-photo-column {
			.intro-photo {
				border: 4px solid #0D2B48;
				padding: 0;
				.intro-photo-image {
					border: 4px solid #0D2B48;
					margin: 6px;
					width: calc( 100% - 12px );
				}
			}
		}
		
		.intro-text-column {
			.intro-text {
				@include mobile-version() {
					padding-bottom: 1.3em;	
				}
				h1.intro-text-heading {
					font-family: "Roboto Condensed";
					color: #10395b;
					margin-bottom: 1.1em;
				}
				.intro-text-body {
					font-family: 'Roboto';
					color: #10395b;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		padding-top: 1.3em;
		padding-bottom: 1.3em;
		.links-column {
			.links-link-list-row {
				a.link {
					@extend .flip-button;
					display: block;
					justify-content: center;
					background-color: transparent;
					color: white;
					font-family: 'Roboto Condensed';
					align-items: center;
					padding: 0;
					text-align: center;
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
					&:before {
						background-color: #10395b;	
						padding: 0.2em 0.6em;
					}
					&:after {
						background-color: #3C7894;
						padding: 0.2em 0.6em;
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
			}
		}
	}
	.carousel-row {
		padding-top: 0;
		padding-bottom: 0;
		.carousel-column {
			.swiper-pagination-bullet {
				background-color: #10395B;
				opacity: 1;
			}
			.swiper-pagination-bullet-active {
				background-color: #94b4c4;
			}
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						padding-top: 1em;	
						padding-bottom: 1em;
						background: #eaebea;
						.card-photo-column {
							img {
								border-color: white;
							}
						}
						.card-contents-column {
							h2.card-heading {
								margin-top: 1em;
								font-family: 'Roboto Condensed';
								font-size: 1.420em;
								color: #10395b;
							}
							div.card-body {
								margin-top: 1em;
								font-family: 'Roboto';
								font-size: 99.787%;
								color: #10395b;
							}
							a.card-button {
								background-color: #3c7894;
								color: white;
								font-family: 'Roboto';
								align-items: center;
								padding: 0.2em 2em;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		margin-top: 1.3em;
		background: url('./testimonial-background.jpg');
		background-size: cover;
		background-position: center;
		.testimonial-photo-column {
			@include mobile-version() {
				padding: 0 1.72em;	
			}
			img {
				border-color: white;
				background-color: white;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Roboto Condensed';
				font-size: 1.620em;
				color: white;
			}
			.testimonial-body {
				font-family: 'Roboto';
				color: white;
			}
			.testimonial-attribution {
				font-family: 'Roboto';
				color: white;
				font-size: 0.892em;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		background: #EEEEEC;
		padding-top: 1.3em;
		padding-bottom: 1.3em;
		margin-bottom: 0;
		.contact-column {
			@include mobile-version() {
				padding-top: 1em;
				padding-bottom: 1em;
			}
			@include desktop-version() {
				padding-top: 0.2em;
				padding-bottom: 0.2em;
			}
			h3.contact-name {
				font-family: 'Roboto Condensed';
				font-size: 1.620em;
				color: #10395B;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
						color: #10395B;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #3C7894;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #3C7894;
					color: white;
				}
			}
			ul.contact-social-media-list {
				margin-bottom: 0;
				li {
					a {
						i {
							color: #3C7894;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		@include mobile-version() {
			padding: 1em 2.72em;
			padding-top: 0;
			max-width: unset;	
		}
		.contact-accent-photo-wrapper {
			width: fit-content;
			margin: auto;
			border: 4px solid #0D2B48;
			padding: 0;
			img.contact-accent-photo {
				border: 4px solid #0D2B48;
				margin: 6px;
				width: calc( 100% - 12px );
			}
		}
	}
}

