$image-max-width: 465px;

.bio.theme-burgundy-dreams {
	a.cta-button {
		background-color: #763548;
		border-radius: 6px;
		color: white;
		font-family: 'Open Sans';
		font-weight: 500;
		padding-top: 0.2em;
		padding-right: 0.6em;
		padding-bottom: 0.2em;
		padding-left: 0.6em;
		
		img {
			width: 1.0804em;
			height: 1.2em;
			margin-left: 0.6em;
		}
	}
	
	.intro-row {
		@include desktop-version() {
			padding: 0.5em 3em;
		}
		background-color: #f1f1f1;
		.intro-photo-column {
			.intro-photo {
				&::before {
					pointer-events: none;
					content: "";
					position: absolute;
					background: url('./intro-photo-splash.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					@include desktop-version() {
						top: -10%;
						left: -22%;
						width: 50%;
						height: calc(50% * 0.589); // Keep aspect ratio
					}
					@include mobile-version() {
						top: -12.2%;
						left: -27%;
						width: 75%;
						height: calc(75% * 0.589); // Keep aspect ratio
					}
				}
				.intro-photo-image {
					position: relative;
					@include desktop-version() {
						border: 0.4em solid #836f75;	
					}
					@include mobile-version() {
						border: 0.6em solid #836f75;	
					}
				}
			}
		}
		
		.intro-text-column {
			.intro-text {
				h1.intro-text-heading {
					font-family: "DM Serif Display";
					font-weight: 700;
					font-size: 1.3em;	
					color: #6f1023;
					margin-bottom: 1.1em;
					position: relative;
					&::after {
						pointer-events: none;
						content: "";
						position: absolute;
						max-width: 170px;
						width: 100%;
						height: 100%;
						background: url('./swash.png');
						background-position: center;
						background-repeat: no-repeat;
						background-size: 100%;
						top: 65%;
						left: 50%;
						transform: translate(-50%, 0);
					}
				}
				.intro-text-body {
					font-family: 'Open Sans';
					color: black;
				}
				.edit-button {
					margin-left: 0;
				}
			}
		}
	}
	.links-row {
		background-color: white;
		.links-column {
			.links-link-list-row {
				margin-bottom: 0.7em;
				a.link {
					@include desktop-version() {
						font-size: 67%;
					}
					display: block;
					position: relative;
					color: black;
					font-family: 'Open Sans';
					font-weight: 500;
					text-align: center;
					padding: 0.2em 0.6em;
					margin-top: 1.1em;
					margin-bottom: 1.1em;
				}
				a.link:first-of-type {
					margin-top: 0;	
				}
				img.link-underline-image {
					max-width: 400px;
					width: 70%;
					height: auto;	
				}
			}
		}
	}
	.carousel-row {
		@include desktop-version() {
			margin-top: 0;
			margin-bottom: 0;
		}
		background-color: #f1f1f1;
		padding: 1em 0;
		.carousel-column {
			.swiper-pagination-bullet {
				background-color: #836f75;
			}
			.swiper-pagination-bullet-active {
				background-color: #763548;
			}
			
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 30px;
						height: auto;
					}
				}
				.carousel-previous-button {
					@include mobile-version() {
						left: 0;
					}
				}
				.carousel-next-button {
					@include mobile-version() {
						right: 0;	
					}
				}
				.swiper-slide {
					@include mobile-version() {
						padding-left: 0.2em;
						padding-right: 0.2em;	
					}
					.carousel-card {
						.card-photo-column {
							img {
								border-color: #836f75;
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 0;
							}
							h2.card-heading {
								position: relative;
								font-family: 'DM Serif Display';
								font-size: 1.2em;
								color: #6f1023;
								
								@include desktop-version() {
									margin-top: 0.6em;	
								}
								@include mobile-version() {
									margin-top: 1.25em;	
								}
								&::after {
									pointer-events: none;
									content: "";
									position: absolute;
									max-width: 170px;
									width: 100%;
									height: 100%;
									background: url('./swash.png');
									background-position: center;
									background-repeat: no-repeat;
									background-size: 100%;
									top: 65%;
									left: 50%;
									transform: translate(-50%, 0);
									z-index: -1;
								}
							}
							div.card-body {
								margin-top: 1em;
								font-family: 'Open Sans';
							}
							a.card-button {
								background-color: #763548;
								border-radius: 6px;
								color: white;
								font-family: 'Open Sans';
								align-items: center;
								padding-top: 0.2em;
								padding-right: 0.6em;
								padding-bottom: 0.2em;
								padding-left: 0.6em;
								margin-left: 0;
								
								img {
									width: 1.0804em;
									height: 1.2em;
									margin-left: 0.6em;
								}
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background:  white;	
		.testimonial-photo-column {
			img {
				border-color: #836f75;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				position: relative;
				font-family: 'DM Serif Display';
				color: #6f1023;
				
				&::after {
					pointer-events: none;
					content: "";
					position: absolute;
					max-width: 170px;
					width: 100%;
					height: 100%;
					background: url('./swash.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;
					top: 65%;
					left: 50%;
					transform: translate(-50%, 0);
				}
			}
			.testimonial-body {
				font-family: 'Open Sans';
				color: black;
			}
			.testimonial-attribution {
				font-family: 'Open Sans';
				color: black;
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		@include desktop-version() {
			padding: 2em 4.3em;	
		}
		background-color: #f1f1f1;
		margin-top: 0;
		margin-bottom: 0;
		.contact-column {
			@include mobile-version() {
				padding-top: 0;
			}
			@include desktop-version() {
				padding-top: 0.2em;
				padding-bottom: 0.2em;
			}
			h3.contact-name {
				position: relative;
				font-family: 'DM Serif Display';
				color: #6f1023;
				&::after {
					pointer-events: none;
					content: "";
					position: absolute;
					max-width: 170px;
					width: 100%;
					height: 100%;
					background: url('./swash.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;
					top: 65%;
					left: 50%;
					transform: translate(-50%, 0);
				}
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
						color: black;
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: #ad5877;
					}
				}
			}
			div.contact-vcard {
				button.contact-vcard-open-button {
					background-color: #ad5877;
					color: white;
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: #ad5877;
						}	
					} 
				}
			}
		}
	}
	.contact-accent-column {
		.contact-accent-photo-container {
			position: relative;
			&::before {
				pointer-events: none;
				content: "";
				position: absolute;
				z-index: 0;
				background: url('./intro-photo-splash.png');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				@include desktop-version() {
					top: -7.2%;
					left: -12%;
					width: 50%;
					height: calc(50% * 0.589); // Keep aspect ratio
				}
				@include mobile-version() {
					top: -15.2%;
					left: -36%;
					width: 95%;
					height: calc(95% * 0.589); // Keep aspect ratio
				}
			}
			img.contact-accent-photo {
				z-index: 1;
				position: relative;
				border-color: #836f75;
			}
		}
	}
}