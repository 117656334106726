.bio.theme-simple-black-and-white {
	a.cta-button {
		background-color: black;
		color: white;
		font-family: 'Oswald';
		border-radius: 12px;
		font-size: 107%;
		img {
			width: 1.38em;
			height: 1.2em;
			margin-left: 0.5em;
		}
	}
	.intro-row {
		@include desktop-version() {
			padding-top: 0.65em;
			padding-bottom: 0.65em;	
		}
		background: black;
		
		.intro-photo-column {
			.intro-photo {
				background-color: #ebebeb;
			}
		}
		
		.intro-text-column {
			.intro-text {
				color: white;
				h1.intro-text-heading {
					font-family: "Oswald";
				}
				.intro-text-body {
					font-family: 'Roboto';
				}
				a.cta-button {
					background-color: #efefef;
					color: black;
				}
			}
		}
	}
	.links-row {
		.links-column {
			.links-link-list-row {
				a.link {
					background-color: black;
					color: white;
					font-family: 'Oswald';
					border-radius: 12px;
					align-items: center;
					padding: 0.2em 0.6em;
					@include mobile-version() {
						font-size: 113%;
					}
					@include desktop-version() {
						font-size: 79%;
					}
					img {
						width: 1.38em;
						height: 1.2em;
						margin-left: 0.4em;
					}
				}
				a.link:last-of-type {
					margin-bottom: 0;	
				}
			}
		}
	}
	.carousel-row {
		padding-top: 0;	
		.carousel-column {
			.carousel-cards {
				.carousel-previous-button, .carousel-next-button {
					img {
						width: 40px;
						height: auto;
					}
				}
				.swiper-slide {
					.carousel-card {
						padding-top: 1em;
						padding-bottom: 1em;	
						background-color: #efefef;
						.card-photo-column {
							img {
								border-color: black;
							}
						}
						.card-contents-column {
							@include desktop-version() {
								padding-bottom: 0;	
							}
							h2.card-heading {
								font-family: 'Oswald';
								font-size: 1.420em;
								margin-top: 0.5em;
							}
							div.card-body {
								font-family: 'Roboto';
								font-size: 99.787%;
							}
							a.card-button {
								background-color: black;
								color: white;
								font-family: 'Oswald';
								border-radius: 12px;
								align-items: center;
								padding: 0.2em 0.6em;
								img {
									width: 1.38em;
									height: 1.2em;
									margin-left: 0.5em;
								}
							}
						}
					}
				}
			}
		}
	}
	.testimonial-row {
		background-color: black;
		color: white;
		.testimonial-photo-column {
			img {
				border-color: #efefef;
			}
		}
		.testimonial-contents-column {
			h2.testimonial-heading {
				font-family: 'Oswald';
				font-size: 1.620em;
				font-weight: normal;
			}
			.testimonial-body {
				font-family: 'Roboto';
			}
			.testimonial-attribution {
				font-family: 'Roboto';
			}
			.edit-button {
			}		
		}
	}
	.contact-row {
		.contact-column {
			h3.contact-name {
				font-family: 'Oswald';
				font-size: 1.620em;
			}
			ul.contact-list {
				li {
					a {
						font-family: 'Open Sans';
					}
				}
			}
			ul.contact-click-to-contact {
				li {
					a {
						color: black;
					}
				}
			}
			ul.contact-social-media-list {
				li {
					a {
						i {
							color: black;
						}	
					} 
				}
			}
		}
		div.contact-vcard {
			button.contact-vcard-open-button {
				background-color: black;
				color: white;
			}
		}
		.contact-accent-column {
			img {
				border-color: black;
			}
		}
	}
}